const basePath = '/tst/siii/';

export const environment = {
  environmentName: 'tst',
  production: true,
  sustainabilityHubBaseUrl: 'https://activate-tst.zeigo.com/hub/tst/api/',
  apiBaseUrl: 'https://activate-tst.zeigo.com/tst/siii-core/api',
  notificationsBaseUrl:
    'https://dev-app.resourceadvisor.schneider-electric.com/notifications/nonprod/api',
  notificationAppKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbiI6InplaWdvYWN0aXZhdGVfdGVzdCIsImV4cCI6MTczNjY5NjYxNywiaXNzIjoiU0UuU3VzdGFpbmFiaWxpdHkuUGxhdGZvcm0uTm90aWZpY2F0aW9ucy1ub25wcm9kIiwiYXVkIjoiU0UuU3VzdGFpbmFiaWxpdHkuUGxhdGZvcm0uTm90aWZpY2F0aW9ucy1ub25wcm9kIn0.7S1L4ptkAU_MSjHojRflHe3w3hcarOXDvbkT-cJDNeY',
  botApiBaseUrl: 'https://activate-dev.zeigo.com/copilot/api',
  zenDeskLink: 'https://activate-zeigo.zendesk.com',
  basePath,
  auth: {
    authClientId: 'd08806a2-0d27-43d5-8b72-b34d0df0cd74',
    signUpSignIn: {
      name: 'B2C_1A_SIGNIN',
      authority: 'https://subtstb2c.b2clogin.com/subtstb2c.onmicrosoft.com/B2C_1A_SIGNIN',
    },
    authorityDomain: 'subtstb2c.b2clogin.com',
    redirect: basePath,
    postLogoutRedirect:
      'https://se--uatbfo22.sandbox.my.site.com/identity/IDMS_IDPSloInit?post_logout_redirect_uri=https%3A%2F%2Fsubtstb2c.b2clogin.com%2Fsubtstb2c.onmicrosoft.com%2FB2C_1A_SIGNIN%2Foauth2%2Fv2.0%2Flogout%3Fpost_logout_redirect_uri%3Dhttps%3A%2F%2Factivate-tst.zeigo.com%2Ftst%2Fsiii%2Fhome%2Fdashboard&app=ProjectS3',
    scopes: ['https://subtstb2c.onmicrosoft.com/siii-core-api/siii-core-api'],
    changePasswordURL:
      'https://se--uatbfo22.sandbox.my.site.com/identity/idmspasswordupdate?app=ProjectS3',
  },
  logForNonProd: (s: string): void => {
    console.log(s);
  },
  bot: 'ZA',
  mapboxAccessToken:
    'pk.eyJ1Ijoic2NobmVpZGVyZXNzbm9ucHJvZCIsImEiOiJja2ZwczJmY2EyOTY2MnNtang4bmU0ZW11In0.76yWkJ8VDCn83AmyLsFSNg',
  mapboxUrl: 'https://api.mapbox.com/search-js/v1.0.0-beta.14/web.js',
};
